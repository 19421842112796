import Image from 'next/image';
import Link from 'next/link';
import { ComponentProps } from 'react';

import { Logo } from '@paytome.co/icon';
import { cn } from '@paytome.co/lib';
import { RegisterForm } from '@paytome.co/mui/components/auth';
import { EditSubscription } from '@paytome.co/mui/components/auth/register/EditSubscription';
import { CONFIG } from '@paytome.co/shared';
import { Subscription } from '@paytome.co/type';

interface Props extends ComponentProps<'div'> {
  subscription: Subscription;
}

export default function Register({ className, subscription, ...rest }: Props) {
  return (
    <div className={cn('container', className)} {...rest}>
      <div className='py-4'>
        <div className='flex items-center justify-center gap-8'>
          <div className={'hidden max-w-[600px] lg:block'}>
            <Image priority alt='register' src={'/assets/images/pages/auth/register.webp'} width={600} height={572} />
          </div>
          <div className={'max-w-[400px]'}>
            <Link target={'_blank'} href={CONFIG.publicUrl}>
              <Logo className={'mb-4'} />
            </Link>

            <h2 className={'mb-2 text-xl font-semibold'}>Welcome to {CONFIG.appName}!</h2>
            <p className={'text-gray-500'}>Please register and start the adventure.</p>
            <EditSubscription subscription={subscription} />

            <RegisterForm subscription={subscription} />
          </div>
        </div>
      </div>
    </div>
  );
}
