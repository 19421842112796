'use client';

import { ComponentProps, Suspense, useState } from 'react';

import { Checkbox, FormControl, FormControlLabel, IconButton } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { Eye, EyeOff } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';
import { PrimaryTextField } from '@paytome.co/mui';
import { Login2faModal, LoginSchema } from '@paytome.co/mui/components/auth';
import { useAuth } from '@paytome.co/mui/components/auth/hooks';
import { yupResolver } from '@paytome.co/mui/resolver';
import { Button, HelperText, PrimaryLink } from '@paytome.co/shared';

type Props = ComponentProps<'form'>;

export function LoginForm({ className, ...rest }: Props) {
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [show2fa, setShow2fa] = useState(false);
  const [loginResponse, setLoginResponse] = useState({});
  const [resErrors, setResErrors] = useState('');
  const [sentToInfo, setSentToInfo] = useState({ email: '', phone: '' });

  const PasswordIcon = showPassword ? Eye : EyeOff;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: undefined, password: undefined },
    resolver: yupResolver(LoginSchema),
    mode: 'onChange',
  });

  const onSubmit = (data: { email?: string; password?: string }) => {
    const { email, password } = data;

    login
      .mutateAsync({
        email: email,
        password: password,
        action_name: 'login',
      })
      .then(
        (res: {
          statusCode: number;
          data: { status: boolean; sent_to_email?: string; sent_to_phone?: string; message: string };
        }) => {
          if (res.statusCode === 301 && res.data.status) {
            const resData = res?.data || {};

            setSentToInfo({
              ...sentToInfo,
              email: res?.data?.sent_to_email ?? '',
              phone: res?.data?.sent_to_phone ?? '',
            });

            setShow2fa(true);
            setLoginResponse({
              ...resData,
              email,
              password,
              action_name: 'login',
            });
          } else {
            setResErrors(res?.data?.message);
          }
        }
      );
  };

  return (
    <>
      {show2fa && (
        <Suspense>
          <Login2faModal
            sentToInfo={sentToInfo}
            show2fa={show2fa}
            setShow2fa={setShow2fa}
            loginResponse={loginResponse}
          />
        </Suspense>
      )}

      <form className={cn('', className)} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} {...rest}>
        <div className={'space-y-4'}>
          <FormControl fullWidth>
            <Controller
              name='email'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <PrimaryTextField
                  autoFocus
                  id='email'
                  type='email'
                  size='small'
                  label='Email*'
                  autoComplete='username'
                  value={value}
                  onBlur={onBlur}
                  onChange={e => {
                    const { value } = e.target;
                    onChange(value);

                    if (resErrors) {
                      setResErrors('');
                    }
                  }}
                  error={Boolean(errors.email)}
                  placeholder='Enter your email'
                />
              )}
            />

            <HelperText hasError={!!errors?.email} message={errors?.email?.message} />
          </FormControl>

          <div className={'relative'}>
            <FormControl fullWidth>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <PrimaryTextField
                    size='small'
                    id='password'
                    label='Password*'
                    value={value}
                    onBlur={onBlur}
                    autoComplete='current-password'
                    onChange={e => {
                      onChange(e.target.value);

                      if (resErrors) {
                        setResErrors('');
                      }
                    }}
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={'Enter your password'}
                    sx={{ 'input.MuiInputBase-input': { paddingRight: '2.5rem !important' } }}
                  />
                )}
              />

              <HelperText hasError={!!errors?.password} message={errors?.password?.message} />
            </FormControl>

            <div className={'absolute right-4 top-0.5'}>
              <IconButton
                edge='end'
                onMouseDown={e => e.preventDefault()}
                onClick={() => setShowPassword(!showPassword)}
              >
                <PasswordIcon aria-label={'show-hide-password'} className='size-5 text-gray-400' />
              </IconButton>
            </div>
          </div>
        </div>

        <HelperText id={`login-error`} className={'pt-2 text-center'} hasError={!!resErrors} message={resErrors} />

        <div className={'my-4 flex flex-wrap items-center justify-between'}>
          <FormControlLabel
            label='Remember Me'
            control={<Checkbox className={'[&_svg]:size-5'} />}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
                color: 'text.secondary',
              },
            }}
          />
          <PrimaryLink href={'/forgot-password'} className={''}>
            Forgot Password?
          </PrimaryLink>
        </div>

        <Button type='submit' loading={login.isPending} variant={'secondary'} className={'mb-4 w-full'}>
          Sign In
        </Button>

        <div className={'flex items-center justify-center gap-2'}>
          <p className={'text-sm text-gray-500'}>New on our platform?</p>

          <PrimaryLink href={'/register'} className={''}>
            Create an account
          </PrimaryLink>
        </div>
      </form>
    </>
  );
}
