import { useMutation, useQuery } from '@tanstack/react-query';

import { CONFIG, setCookie } from '@paytome.co/shared';
import { KeyValuePairs } from '@paytome.co/type';

import {
  forgotPassword,
  getUserInfo,
  loginToTheApp,
  logoutFromTheApp,
  otpVerification,
  registerToApp,
  resetPassword,
} from './auth-apis';

type Response = {
  statusCode: number;
  data: {
    status: boolean;
    message: string;
    data?: KeyValuePairs;
    errors?: KeyValuePairs;
    token?: string;
    sent_to_email?: string;
    sent_to_phone?: string;
  };
};

const handleSession = async (res: Response) => {
  if ([200, 301].includes(res?.statusCode) && res?.data?.status && res?.data?.token) {
    setCookie(CONFIG.authToken, res.data.token, 365 * 3600 * 24); // 1 Year as it's use for dev and stage only
  }
};

export const useUserInfo = () => {
  return useQuery({
    queryKey: ['userInfo'],
    queryFn: getUserInfo,
    refetchOnWindowFocus: true,
  });
};

export const useAuth = () => {
  const register = useMutation({
    mutationKey: ['registerToApp'],
    mutationFn: registerToApp,
    onSuccess: (res: Response) => handleSession(res),
  });

  const login = useMutation({
    mutationKey: ['loginToApp'],
    mutationFn: loginToTheApp,
    onSuccess: (res: Response) => handleSession(res),
  });

  const verifyOtp = useMutation({
    mutationKey: ['otpVerification'],
    mutationFn: otpVerification,
    onSuccess: (res: Response) => handleSession(res),
  });

  const forgot = useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: forgotPassword,
  });

  const reset = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: resetPassword,
  });

  const logout = useMutation({
    mutationKey: ['logoutFromTheApp'],
    mutationFn: logoutFromTheApp,
  });

  return {
    register,
    login,
    verifyOtp,
    reset,
    forgot,
    logout,
  };
};
