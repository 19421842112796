import Image from 'next/image';
import Link from 'next/link';
import { ComponentProps } from 'react';

import { Logo } from '@paytome.co/icon';
import { cn } from '@paytome.co/lib';
import { CONFIG } from '@paytome.co/shared';

import ForgotPasswordForm from './ForgotPasswordForm';

type Props = ComponentProps<'div'>;

export default function ForgotPassword({ className, ...rest }: Props) {
  return (
    <div className={cn('container', className)} {...rest}>
      <div className='py-4'>
        <div className='flex items-center justify-center gap-12'>
          <div className={'hidden max-w-[450px] lg:block'}>
            <Image
              priority
              width={450}
              height={452}
              alt='forgot-password'
              src={'/assets/images/pages/auth/forgot-password.webp'}
            />
          </div>
          <div className={'max-w-[400px]'}>
            <Link target={'_blank'} href={CONFIG.publicUrl}>
              <Logo className={'mb-4'} />
            </Link>

            <div className={'mb-6'}>
              <h2 className={'mb-2 text-xl font-semibold'}>Forgot Password? {'🔒'}</h2>
              <p className={'text-gray-primary'}>
                Enter your email and we&prime;ll send you instructions to reset your password
              </p>
            </div>
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
}
