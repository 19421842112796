import Image from 'next/image';
import { ComponentProps, Suspense } from 'react';

import { cn } from '@paytome.co/lib';

import ResetPasswordForm from './ResetPasswordForm';

type Props = ComponentProps<'div'>;

export default function ResetPassword({ className, ...rest }: Props) {
  return (
    <div className={cn('container', className)} {...rest}>
      <div className='py-8'>
        <div className='flex items-center justify-center gap-8'>
          <div className={'hidden max-w-[450px] lg:block'}>
            <Image
              priority
              width={450}
              height={452}
              alt='reset-password'
              src={'/assets/images/pages/auth/reset-password.webp'}
            />
          </div>
          <Suspense>
            <ResetPasswordForm />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
