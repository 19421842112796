'use client';

import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { ComponentProps, useState } from 'react';

import { IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';

import { usePopupAlerts } from '@paytome.co/hooks';
import { Logo } from '@paytome.co/icon';
import { ChevronLeft, Eye, EyeOff } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';
import { FormControlTextInput } from '@paytome.co/mui';
import { ResetPasswordSchema, ShowPasswordValidation, useAuth } from '@paytome.co/mui/components/auth';
import { yupResolver } from '@paytome.co/mui/resolver';
import { Button, CONFIG, PrimaryLink } from '@paytome.co/shared';

type Props = ComponentProps<'form'>;

const defaultValues = {
  password: '',
  password_confirmation: '',
  newPassword: '',
  showNewPassword: false,
  confirmNewPassword: '',
  showConfirmNewPassword: false,
};

export default function ResetPasswordForm({ className, ...rest }: Props) {
  const router = useRouter();
  const { reset } = useAuth();
  const { sendNotification, notifyUser } = usePopupAlerts();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const searchParams = useSearchParams();

  // Retrieve the query parameters
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  // const t = searchParams.get('t')

  const PasswordIcon = showPassword ? Eye : EyeOff;
  const ConfirmPasswordIcon = showConfirmPassword ? Eye : EyeOff;

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  });

  const passwordValidations = [
    {
      id: 1,
      hasError: watch('password').length < 8,
      message: 'X Use 8 or More Characters',
    },
    {
      id: 2,
      hasError: !watch('password').match(/(?=.*[a-z])(?=.*[A-Z])/),
      message: 'X Use Upper and Lower-Case Letters (e.g. Aa)',
    },
    {
      id: 3,
      hasError: !watch('password').match(/(\d)/),
      message: 'X Use a Number (e.g. 1234)',
    },
    {
      id: 4,
      hasError: !watch('password').match(/(\W)/),
      message: 'X Use a Symbol (e.g. ! @ # $)',
    },
    {
      id: 5,
      hasError: !watch('password') || watch('password') !== watch('password_confirmation'),
      message: 'X Passwords need to be matched!',
    },
  ];

  const onSubmit = (data: { password: string; password_confirmation: string }) => {
    const { password, password_confirmation } = data;
    reset
      .mutateAsync({
        token,
        email,
        password,
        password_confirmation,
      })
      .then(res => {
        if (res.statusCode === 200) {
          notifyUser('success', res?.data?.message).then(() => router.push('/login'));
        } else {
          sendNotification('error', res?.data?.message);
        }
      });
  };

  return (
    <div className={'max-w-[400px]'}>
      <Link target={'_blank'} href={CONFIG.publicUrl}>
        <Logo className={'mb-4'} />
      </Link>

      <div className={'mb-6'}>
        <h2 className={'mb-2 text-xl font-semibold'}>Reset Password {'🔒'}</h2>
        <p className={'text-gray-primary'}>
          for <strong>{email}</strong>
        </p>
      </div>
      <form className={cn('', className)} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} {...rest}>
        <div className={'relative mb-4'}>
          <FormControlTextInput
            autoFocus
            name='password'
            label='Password*'
            errors={errors}
            control={control}
            willShowError={false}
            placeholder={'********'}
            autoComplete={'new-password'}
            type={showPassword ? 'text' : 'password'}
            sx={{ 'input.MuiInputBase-input': { paddingRight: '2.5rem !important' } }}
          />

          <div className={'absolute right-4 top-0.5'}>
            <IconButton edge='end' onMouseDown={e => e.preventDefault()} onClick={() => setShowPassword(!showPassword)}>
              <PasswordIcon aria-label={'show-hide-new-password'} className='size-5 text-gray-400' />
            </IconButton>
          </div>
        </div>

        <div className={'relative mb-4'}>
          <FormControlTextInput
            autoComplete={'new-password'}
            placeholder={'********'}
            control={control}
            errors={errors}
            name='password_confirmation'
            label='Confirm Password*'
            willShowError={false}
            type={showConfirmPassword ? 'text' : 'password'}
            sx={{ 'input.MuiInputBase-input': { paddingRight: '2.5rem !important' } }}
          />

          <div className={'absolute right-4 top-0.5'}>
            <IconButton
              edge='end'
              onMouseDown={e => e.preventDefault()}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <ConfirmPasswordIcon aria-label={'show-hide-confirm-password'} className='size-5 text-gray-400' />
            </IconButton>
          </div>
        </div>

        <ShowPasswordValidation validations={passwordValidations} />

        <Button loading={reset.isPending} type='submit' variant={'secondary'} className={'my-4 w-full'}>
          Set New Password
        </Button>

        <div className={'flex items-center justify-center gap-1'}>
          <ChevronLeft className={'size-5'} />
          <PrimaryLink href='/login'>Back to login</PrimaryLink>
        </div>
      </form>
    </div>
  );
}
